.navBarContainer {
    background-color: #0080C3;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    height: 50px;
    pointer-events: none;
    margin-top: -5px;
    margin-left: 30px;
    margin-right: 10px;
}

.sideDiv {
    display: flex;
    flex-direction: row;
    align-items: baseline ;
}

.navbar, .offcanvas {
    padding: 0 !important;
}

.navbar-brand {
    padding: 0 !important;
}

.container-fluid {
    padding: 0 !important;
}

.navBarSmall {
    display: flex;
    flex-direction: column;
}

.custom-navbar-toggler {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    margin-top: 30px;
    color: #ffffff !important;
}

.offCanvaDiv {
    display: flex;
    flex-direction: column;
}

.offCanvaTitle {
    color: #ffffff !important;
}

.offcanvas-header {
    display: flex;
    justify-content: space-between;
}
  
.headerName {
    color: #0080C3;
    text-align: center;
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 20px;
    font-weight: bold;

}

.headerName::after {
    content: "";
    display: block;
    width: 75%;
    height: 1px;
    background-color: #ED1A29;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.translationList {
    list-style: none;
    gap: 1rem;
    color: #ffffffff;
    font-size: small;
    text-align: center;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row; 
    font-weight: bold;
    gap: 8px;
}

.translationList li {
    text-align: left;
  }
  
.translation {
    cursor: pointer;
    color: white;
    text-decoration: none;   
    padding: 0px 2px;
}

.translation:hover {
    font-weight: bolder;
}

li:not(:last-child) {
    border-right: 2px solid #fff;
    padding-right: 8px;
  }

.leftButton {
    display: flex;
    color: #ffffffff;
    font-size: 20px;
    width: 100%;
    height: 100%;
    cursor: pointer;
    padding: 10px;
}

a {
    text-decoration: none !important;
}

button {
    margin-bottom: 20px !important;
    margin-right: 10px !important;
    margin-left: 10px !important;
}
  


