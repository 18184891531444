.custom-select__control {
    border-radius: 15px !important;
    border-color: #ccc !important;
    width: 100%;
}

.custom-select__control:hover {
    border-color: #0080C3!important;
}

.custom-select__menu {
    border-radius: 10px !important;
}

.custom-select__menu-list {
    border-radius: 10px !important; 
}

.custom-select__indicator {
    color: #0080C3 !important;
}
