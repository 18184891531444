body {
  margin: 0;
  height: 100%;
}

#root {
  height: 100%;
}

@font-face {
  font-family: 'EnglishFont';
  src: url('./assets/fonts/OpenSans/OpenSans-Regular.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ArabicFont';
  src: url('./assets/fonts/Tajawal/Tajawal-Regular.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

.english {
  font-family: 'EnglishFont', sans-serif;
}

.arabic {
  font-family: 'ArabicFont', sans-serif;
  direction: rtl; 
}
