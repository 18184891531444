.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
    
}
.container-sub {
  display: flex;
  flex-direction: column;
  flex-direction: column;
  min-height: 100vh;
}

.num {
  padding-left: 25px;
  padding-right: 25px;
  color: #0080C3;
  margin-top: 20px;
  font-weight: bold;
}

.line {
  border: 1px solid #ED1A29; 
  margin: 0 20px;
  margin-top: 5px;
}

.rowStyle {
  margin-top: 2% !important;
  justify-content: center;
}

.divStyle {
  box-shadow: 0 0 15px #0000001A;
  margin-bottom: 10px;
  min-height: 335px;
}

.img{
  height: 60px;
  width: 50px;
  margin-top: -5px;
}

.name {
  color: #007DBC !important;
  margin-top: 10px;
  font-weight: bold !important;
  width: 100%;
}

html[dir="rtl"] .name {
  text-align: right;
  margin-right: 20%;
}

html[dir="ltr"] .name {
  text-align: left;
}

.shopId {
  color: #eff2f5 !important;
  margin: 10px 10px;
  margin-left: -0.3px;
  font-size: small;
  font-weight: bold;
}

.popUpTitle {
  color: #ED1A29 !important;
  font-weight: bold;
}


.details {
  font-family: Arial, Helvetica, sans-serif;
  font-size: large;
  font-weight: 500;
}

.promotionDiv {
  position: relative;
  border: 1px solid #0080C3;
  overflow: hidden;
  padding: 10px 10px;
  box-shadow: 0 0 1px #0080C3;
  width: 100%;
  min-height: 120px;
  border-radius: 12px;
  margin-bottom: 10px;
}

.promotionTxt {
  margin-left: 5px;
  margin-right: 5px;
  width: 100%;
}

.load {
  color: #0080C3;
  margin-left: 30px;
  margin-right: 30px;
  font-weight: bold;
}

.buttonIcon {
  width:27px;
  height: 27px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: 1px;
  margin-right: 1px;
}

.buttonText {
  padding-top: 10px;
  font-size: large;
  font-weight: bolder !important;
  text-decoration: none;
  color: #0080C3;
  text-align: center;
}


.accordion-button {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  color: #0080C3 !important;
}

.accordion-button::after {
  margin: 7% 10%;
  margin-bottom: 15px;
  color: #0080C3 !important;
}

.cardStyle {
  background-color: #F4F5F6 !important;
  box-shadow: 0 0 2px #E4E6E7 !important;
  margin-bottom: 10px;
  border-radius: 12px !important;
}

.cardHeader {
  background-color: #ffffff;
  width: 100%;
  border-radius: 12px;
  margin-top: 10px;
  margin-bottom: -10px;
  padding-top: 17px;
  padding-bottom: 10px;
  height: 20%;
}

html[dir="rtl"] .cardHeader {
  margin-right: 5px; 
  margin-left: -19%; 
  padding-left: 20%; 
}

html[dir="ltr"] .cardHeader {
  margin-right: -19%; 
  margin-left: 5px; 
  padding-left: 20px; 
}


.cardFooter {
  background-color: #f9fbfc !important;
  height: 45px;
  border-radius: 12px;
}

.locationButton {
  margin: 5px 5px !important;
}

.icon {
  height: 25px;
  width: 20px;
  margin-left: 5px;
  margin-right: 5px;
}

.disabilityDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}