.inputDiv{
   margin: 0 auto; 
   width: 75%;
}

.input{
   border-radius: 15px !important;
   border: 1px solid #ccc;
   width: 100%;
   height: 38px;
   padding-left: 35px;
   background: url("/public/images/searchIcon.png") no-repeat 10px center;
   background-size: 18px;
   outline: none;
}

.input:hover {
   border-color: #0080C3 !important;
}

.input:focus {
   border: 2px solid #0080C3;
}

:dir(rtl) .input {
   padding-left: 10px; 
   padding-right: 40px; 
   background-position: right 10px center;
}

.row-spacing {
   margin-bottom: 10px;
}

.filterBtn{
   margin: auto;
   margin-top: 25px;
   width: 25%;
   height: 35px;
   color: #0080C3;
   background-color: #ffffff;
   border: none;
   font-size: medium;
   font-weight: bold;
   box-shadow: 0 0 10px #0000001A;
   cursor: pointer;
   border-radius:5px;
}

.num {
   padding-left: 25px;
   padding-right: 25px;
   color: #0080C3;
   margin-bottom: 5px;
}

.line {
   border: 1px solid #0080C3; 
   margin: 0 20px;
   margin-top: 5px;
}

.divider {
   display: flex;
   align-items: center;
   justify-content: center;
   margin: 20px auto;
   width: 100%;
 }
 
 .divider::before,
 .divider::after {
   content: "";
   border-bottom: 1px solid #ED1A29;
   width: 50%; 
 }
 
 .divider h4 {
   min-width: max-content; 
   padding: 0 10px;
   text-align: center;
   color: #0080C3;
  font-weight: bold;
 }

.iconsFilter {
   display: flex;
   flex-direction: row;
   justify-content: center;
   text-align: center;
   color: #0080C3;
   border-bottom: 1px solid #ED1A29;
}

.iconsFilter div {
   display: flex;
   flex-direction: column;
   align-items: center;
   min-width: 80px;
   gap: 10px;
}

.iconStyle {
   width: 75%;
   height: auto;
   cursor: pointer;
   border-radius: 5px;
}

.pageName {
   min-height: 40px;
   display: flex;
   align-items: center;
   justify-content: center;
   text-align: center;
   white-space: nowrap;
   font-weight: bold;
}
